import { Component, forwardRef, Injector, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective, NG_VALUE_ACCESSOR,
  NgControl
} from '@angular/forms';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

// Providers
export const INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PhoneInputComponent),
  multi: true,
};

// Models

export interface ITelInputValue {
  countryCode: string,
  dialCode: string,
  e164Number: string,
  internationalNumber: string,
  nationalNumber: string,
  number: string,
}

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [INPUT_VALUE_ACCESSOR]
})
export class PhoneInputComponent implements OnInit, ControlValueAccessor {
  public separateDialCode: boolean = true;
  public SearchCountryField = SearchCountryField;
  public CountryISO = CountryISO;
  public PhoneNumberFormat = PhoneNumberFormat;
  public preferredCountries: CountryISO[] = [CountryISO.UnitedKingdom];

  public control: FormControl;

  public value: string = '';
  public disableState = false;

  private onChange: (p: any) => void = () => {
  };
  private onTouched: (p: any) => void = () => {
  };

  get isInvalid(): boolean {
    return !this.control.valid && this.control.touched && this.control.value;
  }

  constructor(private injector: Injector) {
  }

  ngOnInit(): void {
    this.getControl();
  }

  getControl(): void {
    const ngControl = this.injector.get(NgControl);
    if (ngControl instanceof FormControlName) {
      this.control = this.injector.get(FormGroupDirective).getControl(ngControl);
      return;
    }
    this.control = (ngControl as FormControlDirective).form as FormControl;
  }

  writeValue(value: ITelInputValue | string | null): void {
    if (typeof value === 'string') {
      this.value = value;
      return;
    }
    if (value === null) {
      this.value = '';
    }
    this.value = value?.internationalNumber || '';
  }

  registerOnChange(fn: (p: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (p: any) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disableState = isDisabled;
  }

  changeValue(event: ITelInputValue): void {
   this.onChange(event);
   this.onTouched(event);
  }
}
