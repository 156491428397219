/**
 * Created by Shahil G. on 09/06/2021.
 * Copyright © 2021 [InstaCare]. All rights reserved.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

import { ISubscribeCompany, ISubscribeCompanyResponse, IPlanList } from '../interfaces/app.interface';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private baseApiLink = environment.apiUrl;
  private evButtonEventData$ = new Subject<boolean>();

  constructor(private http: HttpClient) {
  }

  public plansList(): Observable<IPlanList> {
    return this.http.get<IPlanList>(`${ this.baseApiLink }subscriptions/get-plans`);
  }

  public subscribeCompany(body: ISubscribeCompany): Observable<any> {
    return this.http.post<ISubscribeCompanyResponse | {}>(`${ this.baseApiLink }subscriptions/subscribe-company`, body,
      { observe: 'response' });
  }

  public evButtonEvent(evMessage: boolean): void {
    this.evButtonEventData$.next(evMessage);
  }

  public onEvButtonEvent(): Observable<boolean> {
    return this.evButtonEventData$.asObservable();
  }
}
