import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Phone Input library
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

// Material modules
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

// Components
import { SubscriptionPopupComponent } from './components/subscription-popup/subscription-popup.component';
import { SubscriptionItemsComponent } from './components/subscription-items/subscription-items.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';

@NgModule({
  declarations: [
    SubscriptionPopupComponent,
    SubscriptionItemsComponent,
    PreloaderComponent,
    PhoneInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    NgxIntlTelInputModule
  ],
  entryComponents: [
    SubscriptionPopupComponent
  ],
  exports: [
    SubscriptionPopupComponent,
    SubscriptionItemsComponent,
    PreloaderComponent
  ]
})
export class SharedModule {
}
