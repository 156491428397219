<ngx-intl-tel-input
  [class.invalid]="isInvalid || disableState"
  [cssClass]="'custom'"
  [numberFormat]="PhoneNumberFormat.International"
  [preferredCountries]="preferredCountries"
  [enableAutoCountrySelect]="false"
  [enablePlaceholder]="true"
  [searchCountryFlag]="true"
  [separateDialCode]="separateDialCode"
  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
  [selectFirstCountry]="false"
  [selectedCountryISO]="CountryISO.UnitedKingdom"
  [maxLength]="10"
  [phoneValidation]="true"
  [value]="value"
  [formControl]="control"
  (ngModelChange)="changeValue($event)"
></ngx-intl-tel-input>
